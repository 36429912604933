* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}

h3 {
	font-size: 3rem;
	font-weight: bold;
	padding-bottom: 2rem;
	text-align: center;
}

.skills-container {
	display: block;
	background: #242424;
	color: #fff;
	padding-bottom: 2rem;
	text-align: center;
	width: 70vw;
	margin: auto;
}

.skills {
	display: inline-flex;
	flex-wrap: wrap;
	padding-bottom: 3rem;
	align-items: center;
	justify-content: center;
}

.skill {
	padding: 1rem 1rem;
	margin-bottom: 1.5rem;
	margin-right: 1.5rem;
	font-size: 1rem;
	background: rgb(189, 88, 5);
	border-radius: 5px;
	font-weight: 600;
	color: #fff;
}

.skills-container > .btn {
	display: block;
	width: 70vw;
	margin: auto;
}

a,
.fa-file-arrow-down {
	color: #fff;
}

.btn:hover a,
.btn:hover .fa-file-arrow-down {
	color: #242424;
}
