* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}

h1 {
	padding: 2rem 0rem;
	font-weight: bold;
	word-break: break-word;
}

img {
	float: left;
	padding: 1rem 2rem 0rem 2rem;
	height: auto;
	max-height: 400px;
	max-width: 400px;
	width: fit-content;
}

p {
	text-align: justify;
	font-size: 1.5rem;
	padding: 0.5rem 2rem 1rem 2rem;
}

.textblock {
	display: block;
	height: fit-content;
	font-size: 3rem;
	background: #242424;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	padding-bottom: 3rem;
	width: 70vw;
	margin: auto auto;
}

@media screen and (max-width: 821px) {
	.image-container {
		display: flex;
		justify-content: center;
	}

	img {
		padding: 2rem 1rem;
		max-height: 375px;
		max-width: 375px;
	}

	p {
		padding: 0rem;
	}
}
