.footer-container {
	background: radial-gradient(circle, rgb(189, 88, 5) 0%, rgb(84, 39, 5) 100%);
	display: flex;
}

.footer-left {
	align-items: left;
	justify-content: center;
	padding: 5px;
	width: calc(100% / 3);
}

.footer-center {
	align-items: center;
	align-self: flex-end;
	display: flex;
	justify-content: center;
	padding-bottom: 2px;
	width: calc(100% / 3);
}

.footer-right {
	align-items: right;
	justify-content: center;
	padding: 5px;
	width: calc(100% / 3);
}

.fl {
	float: left;
}

.fr {
	float: right;
}

.fa-envelope {
	color: #fff;
}

.website-rights {
	color: #fff;
}

@media screen and (max-width: 480px) {
	small {
		font-size: 0.5rem;
	}
}
