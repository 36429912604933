* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'PT Sans', sans-serif;
}

.wrapper {
	min-height: 100%;
	position: relative;
}

.aboutme {
	background: #242424;
	display: flex;
	height: 90.4vh;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.projects {
	background: #242424;
	height: 90.4vh;
}

.project-1,
.project-2,
.project-3,
.project-4,
.project-5 {
	background: #242424;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 5rem;
	height: 90.4vh;
	padding-bottom: 2rem;
}

.project-1 > .btn,
.project-2 > .btn,
.project-3 > .btn,
.project-4 > .btn,
.project-5 > .btn {
	display: block;
	width: 70vw;
	margin: auto;
}

.btn > a:hover,
.btn > a:hover,
.btn > a:hover,
.btn > a:hover,
.btn > a:hover {
	color: #242424;
}

@media screen and (max-width: 1475.5px) {
	.aboutme,
	.project-1,
	.project-2,
	.project-3,
	.project-4,
	.project-5,
	.projects {
		height: fit-content;
	}
}

@media screen and (max-height: 1210.5px) {
	.aboutme,
	.projects {
		height: fit-content;
	}
}

@media screen and (max-height: 960px) {
	.project-1,
	.project-2,
	.project-3,
	.project-4,
	.project-5 {
		height: fit-content;
	}
}
